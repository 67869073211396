@import "../abstracts/variables";
@import "../base/font";

.swal2-container{
    .swal2-popup {
        font-size: 1.6rem !important;
    }
}

.Toastify__toast-body > div:last-child {
    font-size: 1.5rem;
}

.rnc__base{
    z-index: 99999 !important;
}