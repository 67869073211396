.rnc__notification-item--default {

    background-color: #72adec;
    border-left: 8px solid #0562c7;
}
.rnc__notification-item--success {

    top: -11em;
    background-color: #c0c257;
    border-left: 8px solid #c0c257;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    line-height: 1.45;
    letter-spacing: 0.03rem;
    //1f8838
    .rnc__notification-timer {
        background-color: #c0c257;
    }
}
.rnc__notification-item--danger {
    bottom: 10%;

    background-color: #f14749;
    border-left: 8px solid #d6080c;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    line-height: 1.45;
    letter-spacing: 0.03rem;
    z-index: 10000000000 !important;
    .rnc__notification-timer {
        background-color: #d6080c;
    }
}
.rnc__notification-item--warning {

    background-color: #f6d9a0;    
}
.rnc__notification-message{
    color: #ffffff; 
}