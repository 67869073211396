@import "./font";

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    font-size: 62.5%; // 1rem == 10px ( rem depend on root font size )
    background-color: #FDFCF5;
}

body{
    font-family: "cabin-regular", sans-serif !important;
    background-color: #FDFCF5 !important;
}



//BEM naming format
// .block {}
// .block_element {}
// .block_element--modifier {}