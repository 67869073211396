@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(80px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(-80px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
  }

@keyframes blinker {
	50% {
	  opacity: 0;
	}
  }


  @-webkit-keyframes scale-up-center {
	0% {
	  -webkit-transform: scale(0.5);
			  transform: scale(0.5);
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	}
  }
  @keyframes scale-up-center {
	0% {
	  -webkit-transform: scale(0.5);
			  transform: scale(0.5);
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	}
  }


@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px) rotate(-5deg);
  }
  20% {
    transform: translateX(5px) rotate(5deg);
  }
//   30% {
//     transform: translateX(-5px) rotate(-5deg);
//   }
//   40% {
//     transform: translateX(5px) rotate(5deg);
//   }
//   50% {
//     transform: translateX(-5px) rotate(-5deg);
//   }
//   60% {
//     transform: translateX(5px) rotate(5deg);
//   }
//   70% {
//     transform: translateX(-5px) rotate(-5deg);
//   }
//   80% {
//     transform: translateX(5px) rotate(5deg);
//   }
  90% {
    transform: translateX(-5px) rotate(-5deg);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-top {
	0% {
			  transform: translateY(100px);
	}
	100% {
			  transform: translateY(0);
	}
  }

  @keyframes hide-up {
	0% {
	  transform: translateY(-0%);
	  opacity: 1
	}
	100% {
	  transform: translateY(-100%);
	  opacity: 0
	}
  }




  
  
  
  


//   animation: name duration timing-function delay iteration-count direction fill-mode;
