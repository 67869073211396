$color-primary: #E26368;
$color-secondary: #FFE7E7;
$color-third: #C3C4CD;

$text-color--primary: #373A2E;
$text-color--secondary: #83829A;
$text-color--third: #FDFDFF;
$text-color--fourth: #C3C4CD;

$btn-background-color--disable: #F5F5F5;
$btn-color--disable: #83829A;
$btn-background-color--success: #E26368;
$btn-color--success: #FDFDFF;

$background-color--primary: #FDFCF5;
$background-color--secondary: #F5F5E4;
$background-color--third: #FDFDFF;



