@import "../abstracts/variables";

.form-group{
    padding: 0.5em;
    &._text{
        input{
            padding: 0.5em;
            width: 100%;
        }
        input:focus{
            outline: none;
        }
        position: relative;
        align-items: center;
        .verification-button{
            right: 1em;
            width: auto;
            position: absolute;
            button{
                border: none; 
                display: flex;
                font-size: 1rem;
                padding: 0.5em 1em;   
                align-items: center; 
                box-shadow: 0 0.2em #999;
                border-radius: 0.6em;
                color: $text-color--secondary;
                background: rgba(67, 67, 67, 0.1);
            }
            .button:active {
                box-shadow: 0 0.1em #666;
                transform:translateY(2px);
              }
        }
        &.login-field{
            .text-danger{
                font-size: 1.3rem;
            }
            .text{
                border: none;
                border-radius: 0;
                border-bottom: 0.1rem solid #D6D6D6;
            }
            .text:focus{
                outline: none;
            }
        }
        &.service-field {
            margin: 1rem 0;
            color: #d6d6d6;
            align-items: start;
            margin-bottom: 0.1rem;
            border-radius: 0.9rem;
            border: 1px solid #0000002b;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            input::-webkit-date-and-time-value {
                margin-top: 0px;
                text-align: start;
                width: 100%;
            }        
    .form-outline {
      .form-label {
        font-size: 1.4rem;
        // color: #d6d6d6 !important;
        color: #4e4e4e !important; 
      }
      .form-control {
          padding: 0;
          color: #707070;
          font-size: 1.8rem;
          //font-weight: bolder;
          margin: 0.6em 0em 0em 0em;
      }
      .form-control:disabled{
        background-color: transparent !important;
      }
    }
    .form-notch {
      display: none !important;
    }
  }
      }
    &._select{
        select{
            padding: 0.5em;
            width: 100%;
        }
        &.gender{
            margin: 1rem 0;
            color: #d6d6d6;
            align-items: start;
            //font-weight: bolder;
            margin-bottom: 0.1rem;
            border-radius: 0.9rem;
            border: 1px solid #0000002b;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            .form-label{
              margin: 0em 0em 0em 1em;
            //   line-height: 1em;
              font-size: 1.1rem;
            //   color: #d6d6d6 !important;
            color: #4e4e4e !important; 

            }
            select{
                padding: 0;
                width: 100%;
                border: none;
                color: #707070;
                //font-weight: bolder;
                margin: 0.6em 0em;
                background: transparent;

            }
            select:focus{
              outline: none;
            }
          }
    }
    &._dropdown{
        .dropdown{
            .dropdown-toggle{
                background-color: transparent;
                color: black;
                box-shadow: none;
                border: 0.1em solid rgba(0, 0, 0, 0.675);
                padding: 0.5em;
                font-size: 1.6rem;
                width: 100%;
            }
            .dropdown-toggle::after{
                position: absolute;
                top: 1.2em;
                right: 1em;
            }
            .dropdown-menu{
                font-size: 1.6rem;
                width: 100%;
                text-align: center;
                li{
                    padding: 0.5em;
                }
                li:hover{
                    background-color: rgba(239, 239, 239, 0.998);
                    padding: 0.5em;
                }
            }
        }
    }
    &._calendar{
        .p-calendar{
            width: 100%;
            input{
                font-size: 1.6rem !important;
            }
            button{
                width: 5em;
                .pi{
                    font-size: 1.6rem !important;
                }
            }
        }
    }
    &._partail-input{
        margin-top: 1em;
        padding: 0.3em 0.8em;
        .--input{
            display: flex;
            padding: 0.5em;
            align-items: center;
            background: #FFFFFF;
            border-radius: 0.5em 0 0 0.5em;
            input{
                border:none;
                outline: none;
                color: #373A2E;
            }
            input::placeholder{
                color: #D6D6D6;
            }
        }
        .--button{
            display: flex;
            align-items: center;
            background: #E26368;
            justify-content: center;
            border-radius: 0 0.5em 0.5em 0;
            button{
                border:none;
                align-items: center;
                justify-content: center;
                display: flex;
                box-shadow: none;
                color: #F5F5F5;
                //font-weight: 400;
                font-size: 1.4rem;
                text-transform: capitalize;
                background-color: transparent;
            }
        }
    }
    &._button{  
        &.login_button{
            width: 38em;
            .btn-primary{
                height: 2.9em;
                //font-weight: 500;
                box-shadow: none;
                font-size: 1.8rem;
                border-radius: 0.75em;
                color: $btn-color--success;
                background-color:$btn-background-color--success;
            }
            .btn-primary:disabled{
                color: $btn-color--disable;
                background: $btn-background-color--disable;

                &.--loading {
                    background: "#ec999d";
                    color: #fafafa;
                }
            }
        }
        &.withdraw_button{
            .btn-primary{
                width: auto;
                //font-weight: 500;
                color: #FFFFFF;
                font-size: 0.7rem;
                border-radius: 0.55em;
                text-transform: capitalize;
                background-color:$btn-background-color--success;
            }
        }
        &.cart_button{
            bottom: 1%;
            width: 38em;
            position: fixed;
            .btn-primary{
                height: 2.9em;
                color: $btn-color--success;
                background-color:$btn-background-color--success;
            }
        }
        &.cart_button-2{
            width: 38em;
            .btn-primary{
                height: 2.9em;
                color: $btn-color--success;
                background-color:$btn-background-color--success;
            }
        }
        &.alcoholic_button-2{
            width: 21.875em;
            .btn-primary{
                height: 2.9em;
                border-radius: 0.75em;
                color: $btn-color--success;
                background-color:$btn-background-color--success;
            } 
        }
    }
    &._text{
        &.text_remark{
            margin-top: -6em;
            .text{
                border: none;
                border-radius: 0;
                border-bottom: 0.1em solid $text-color--secondary;
            }
        }
    }
    &._checkbox{
        &.login_checkbox_div{
             .text-danger{
                font-size: 1.3rem;
            }
            .login_checkbox{
                ._tccheck[type=checkbox]{
                    height: 0.8em;
                    width: 0.8em;
                    accent-color: $color-primary;
                    border-color: $color-primary;
                    border: 0.9rem solid $color-primary;
                }
                ._span{
                    //font-weight: 400;
                    font-size: 1.2rem;
                    color: $text-color--secondary;
                    &.--note{
                        color: $color-primary;
                    }
                }  
            }
        }
    }
    &._code{
        &.login-field{
            ._label{
                &.--code{
                    //font-weight: 400;
                    font-size: 1.8rem;
                    color: #a1a3b1;
                }
            }
        }
        .ReactInputVerificationCode__container{
            width: 100%;
            .ReactInputVerificationCode__item{
                border-radius: 0;
                box-shadow: none !important;
                border-bottom: 0.1rem solid #C3C4CD!important;
            }
            .ReactInputVerificationCode__item.is-active{
                border-radius: 0;
                box-shadow: none !important;
                border: 0.2rem solid #C3C4CD!important;
            }
        }
        
    }
}
.form-group:focus {
    border: 29em solid #000000d8;
  }
.error-message{
    font-size: 1.3rem;
    // margin: -0.2em 0em 0em 0.5em !important;
}

.button-group{
    width: 100%;
    padding: 0.5em !important;
    font-size: 1.6rem !important;
    &.--sweetAlertHelper{
        border-radius: 0.5em;
    }
}

.p-component{
    .p-link{
        font-size: 1.6rem !important;
    }
    .p-datepicker-calendar-container {
        .p-datepicker-calendar {
          thead {
            tr {
              th {
                text-align: center;
              }
            }
          }
          font-size: small;
        }
      }
}

.count-button {
   text-align: center;    
    align-items: center;
    border-radius: 0.4em;
    background: #FFE7E7;
    &.cart-btn{
        width: 37em;
        bottom: 10%;
        height: 3.9em;
        position: fixed;
        ._label{
            &.--number{
                font-family: "roboto-medium";
                font-size: 1.8rem;
            }
        }
    }
    &.alcoholic-btn{
        width: 21.25em;
        height: 2.875em;
        ._label{
            &.--number{
                font-family: "roboto-medium";
                font-size: 1.8rem;
            }
        }
    }
    &.order{
    background: #F6F6F6;
        width: 100%;
    }
}